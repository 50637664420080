import * as catalogTypes from "./catalog.type";

import { API } from "../../../services/http.service";
import DynamicCatalogPage from "pages/UserPortal/Catalog/DynamicCatalogPage";
import * as homeTypes from "../home/home.type";
import { CATALOG_MODAL_STATE } from "./catalog.type";
const REQUEST_URL = "";

const config = {
  headers: {
    "Content-Type": "application/json-application"
  }
};

export const getCategoryList = id => async dispatch => {
  dispatch({
    type: catalogTypes.GET_CATEGORY_LIST_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/category/${id}`, config);
    const actives = [];
    const activesSubCategory = [];
    response.data.map((item, index) => {
      actives.push(false);
    });
    dispatch({
      type: catalogTypes.GET_CATEGORY_LIST_FULLFILLED,
      payload: response.data,
      actives: actives,
      activesSubCategory: activesSubCategory
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: catalogTypes.GET_CATEGORY_LIST_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

function returnIndex(index) {
  return index;
}

export const setCategoryName = index => async dispatch => {
  dispatch({
    type: catalogTypes.SET_CATEGORY_NAME_PENDING
  });

  try {
    const returnComponent = returnIndex(index);

    dispatch({
      type: catalogTypes.SET_CATEGORY_NAME_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_CATEGORY_NAME_REJECTED,
      payload: error
    });
  }
};

function returnProduct(product) {
  return product;
}

export const setSelectedProduct = product => async dispatch => {
  dispatch({
    type: catalogTypes.SET_SELECTED_PRODUCT_PENDING
  });

  try {
    const returnComponent = returnProduct(product);

    dispatch({
      type: catalogTypes.SET_SELECTED_PRODUCT_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_SELECTED_PRODUCT_REJECTED,
      payload: error
    });
  }
};

export const setSortingAsc = sorting => async dispatch => {
  dispatch({
    type: catalogTypes.SET_SORTING_ASC_PENDING
  });

  try {
    const returnComponent = sorting;

    dispatch({
      type: catalogTypes.SET_SORTING_ASC_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_SORTING_ASC_REJECTED,
      payload: error
    });
  }
};

export const setSortingByProductTypeAsc = productType => async dispatch => {
  dispatch({
    type: catalogTypes.SET_SORTING_BY_TYPE_ASC_PENDING
  });

  try {
    const returnComponent = productType;

    dispatch({
      type: catalogTypes.SET_SORTING_BY_TYPE_ASC_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_SORTING_BY_TYPE_ASC_REJECTED,
      payload: error
    });
  }
};

function returnIndex2(index) {
  return index;
}

export const setSubCategoryName = index => async dispatch => {
  dispatch({
    type: catalogTypes.SET_SUB_CATEGORY_NAME_PENDING
  });

  try {
    const returnComponent = returnIndex2(index);

    dispatch({
      type: catalogTypes.SET_SUB_CATEGORY_NAME_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_SUB_CATEGORY_NAME_REJECTED,
      payload: error
    });
  }
};

function returnText(text) {
  return text;
}

export const setSortingType = text => async dispatch => {
  dispatch({
    type: catalogTypes.SET_SORTING_TYPE_PENDING
  });

  try {
    const returnComponent = returnText(text);

    dispatch({
      type: catalogTypes.SET_SORTING_TYPE_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_SORTING_TYPE_REJECTED,
      payload: error
    });
  }
};

export const setCatalogContent = () => async dispatch => {
  dispatch({
    type: catalogTypes.SET_CATALOG_CONTENT_PENDING
  });

  try {
    const returnComponent = DynamicCatalogPage;
    dispatch({
      type: catalogTypes.SET_CATALOG_CONTENT_FULLFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: catalogTypes.SET_CATALOG_CONTENT_REJECTED,
      payload: error
    });
  }
};

export const getProductsBySubCatId = (
  subCatId,
  sorting,
  score,
  page,
  employeeFirmId
) => async dispatch => {
  dispatch({
    type: catalogTypes.GET_PRODUCTS_PENDING
  });

  try {
    const response = await API.get(
      `${REQUEST_URL}/product/${subCatId}/${sorting}/${score}/${employeeFirmId}?page=${page}&size=12`,
      config
    );
    dispatch({
      type: catalogTypes.GET_PRODUCTS_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: catalogTypes.GET_PRODUCTS_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const getAddresses = empId => async dispatch => {
  dispatch({
    type: catalogTypes.GET_ADDRESSES_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/address/${empId}`, config);
    dispatch({
      type: catalogTypes.GET_ADDRESSES_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: catalogTypes.GET_ADDRESSES_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};

export const catalogChangeState = state => async dispatch => {
  dispatch({ type: catalogTypes.CATALOG_MODAL_STATE, payload: state });
};

export const catalogCartItemChangeState = state => async dispatch => {
  dispatch({ type: catalogTypes.CATALOG_CART_ITEM_STATE, payload: state });
};

export const getSimilarProducts = id => async dispatch => {
  dispatch({
    type: catalogTypes.GET_PRODUCTS_WITH_ID_PENDING
  });

  try {
    const response = await API.get(`${REQUEST_URL}/product/${id}`, config);
    dispatch({
      type: catalogTypes.GET_PRODUCTS_WITH_ID_FULLFILLED,
      payload: response.data
    });

    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: catalogTypes.GET_PRODUCTS_WITH_ID_REJECTED,
      payload: error
    });

    return Promise.reject();
  }
};
