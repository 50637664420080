import React from "react";

const TabMenu = ({ activeTab, handleTabClick }) => {
  return (
    <div className="flex rounded-lg bg-tab-500 p-1 items-center">
      <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 1
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(1)}
      >
        Hesabım
      </div>
      <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 2
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(2)}
      >
        İletişim
      </div>

      {/* Yeni Sezon'da Açılacak */}
      {/* <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 3
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(3)}
      >
        Puanlar
      </div>

      <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 4
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(4)}
      >
        Hediyeler
      </div>

      <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 5
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(5)}
      >
        Kargo
      </div>

      <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 6
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(6)}
      >
        Rozetler
      </div> */}

      <div
        className={`px-4 py-2 cursor-pointer ${
          activeTab === 7
            ? "bg-white text-primary-500 rounded-lg"
            : "bg-tab-500 text-tab-700"
        }`}
        onClick={() => handleTabClick(7)}
      >
        Web, Mobil
      </div>
    </div>
  );
};

export default TabMenu;
