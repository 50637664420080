import * as disclaimerTypes from "./disclaimer.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getIysDisclaimerByErc = erc => async dispatch => {
  dispatch({ type: disclaimerTypes.GET_IYS_DISCLAIMER_BY_ERC_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/employeeFirm/get/iys/${erc}`,
      config
    );
    dispatch({
      type: disclaimerTypes.GET_IYS_DISCLAIMER_BY_ERC_FULFILLED,
      payload: response
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: disclaimerTypes.GET_IYS_DISCLAIMER_BY_ERC_REJECTED,
      payload: error
    });

    return Promise.reject(error);
  }
};

export const saveIysDisclaimer = (erc, confirmed) => async dispatch => {
  dispatch({ type: disclaimerTypes.SAVE_IYS_DISCLAIMER_PENDING });

  const data = { erc, confirmed };
  try {
    const response = await API.post(`/employeeFirm/iys/confirm`, data);

    dispatch({
      type: disclaimerTypes.SAVE_IYS_DISCLAIMER_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: disclaimerTypes.SAVE_IYS_DISCLAIMER_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getDisclaimerByFirmId = (
  id,
  employeeId,
  isHandleRoute = false
) => async dispatch => {
  dispatch({ type: disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_PENDING });
  try {
    const response = await API.get(
      `${REQUEST_URL}/disclaimer/${id}/${employeeId}`,
      config
    );
    if (isHandleRoute) {
      if (!response.data[0].checkedDisclaimerInTheWeek) {
        return Promise.resolve(response);
      } else {
        return false;
      }
    }
    dispatch({
      type: disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: disclaimerTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const saveDisclaimer = (
  disclaimer,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: disclaimerTypes.SAVE_DISCLAIMER_CHECK_PENDING });

  const data = { disclaimer, employeeFirmId, firmId };
  try {
    const response = await API.post(`/disclaimer/save`, data);

    dispatch({
      type: disclaimerTypes.SAVE_DISCLAIMER_CHECK_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: disclaimerTypes.SAVE_DISCLAIMER_CHECK_REJECTED,
      payload: error
    });
  }
};

export const getDisclaimerPhotoByFirmId = (
  firmId,
  employeeFirmId
) => async dispatch => {
  dispatch({ type: disclaimerTypes.GET_DISCLAIMER_PHOTO_BY_FIRMID_PENDING });
  try {
    const response = await API.get(
      `${REQUEST_URL}/imageConfirm/${firmId}/${employeeFirmId}`,
      config
    );
    dispatch({
      type: disclaimerTypes.GET_DISCLAIMER_PHOTO_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: disclaimerTypes.GET_DISCLAIMER_PHOTO_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveDisclaimerPhoto = (
  employeeFirmId,
  imageConfirmId,
  selectedChoice
) => async dispatch => {
  dispatch({ type: disclaimerTypes.SAVE_DISCLAIMER_PHOTO_PENDING });

  let type = "reject";

  if (selectedChoice) {
    type = "save";
  }

  const data = { employeeFirmId, imageConfirmId };
  try {
    const response = await API.post(`/imageConfirm/${type}`, data);

    dispatch({
      type: disclaimerTypes.SAVE_DISCLAIMER_PHOTO_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: disclaimerTypes.SAVE_DISCLAIMER_PHOTO_REJECTED,
      payload: error
    });
  }
};
