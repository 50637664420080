import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "services/http.service";
import { Link } from "react-router-dom";

// Styles
import "./Tac.scss";

const Tac = () => {
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [text, setText] = useState(null);

  useEffect(() => {
    if (text === null) {
      const tac = async () => {
        const resp = await API.get(
          `disclaimer/${employeeFirm.firm.id}/${employeeFirm.id}`
        );
        setText(resp.data[0].description);
      };

      tac();
    }
  }, [employeeFirm.firm.id, employeeFirm.id, text]);

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Şartlar ve Koşullar</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li>
                  <Link to={`/userportal/faq`} className="font-medium">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>

                <li className="active">
                  <Link to={`/userportal/tac`} className="font-medium">
                    Şartlar Koşullar
                  </Link>
                </li>

                {/* Yeni Sezon'da Açılacak */}
                {/* <li>
                  <Link to={`/userportal/score`} className="font-medium">
                    Nasıl Puan Kazanırım
                  </Link>
                </li> */}

                <li>
                  <Link to={`/userportal/userGuide`} className="font-medium">
                    Kullanım Kılavuzu
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/announcement`} className="font-medium">
                    Duyurular
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            <div
              className="bg-white p-3 pp-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Tac;
