import React from "react";
import Accordion from "./Accordion";

const TabContent = ({ activeTab }) => {
  const accordionItemsMyAccount = [
    {
      title: `Hesabıma nasıl ulaşırım?`,
      content: `Benim Bayim ana sayfasının sağ üst kısmındaki, ad - soyadınızın olduğu bölüme tıklayarak profilinizi görüntüleyebilirsiniz.`
    },
    {
      title: `Hesabımda yer alan bayi tabela adı, Satış Belgesi Numarası ve cep telefonu bilgilerimi nasıl değiştirebilirim?`,
      content: `Bayi tabela adı, Satış Belgesi Numarası ve cep telefonu bilgilerinizi değiştirmek için 0850 532 5 584 (JTI) numaralı Benim Bayim Çağrı Merkezini aramanız gerekmektedir.`
    },
    // {
    //   title: `Hesabımda yer alan adres bilgisini nasıl değiştirebilirim?`,
    //   content: `Benim Bayim ana sayfasının sağ üst kısmındaki, ad - soyadınızın olduğu bölüme tıklayarak "Adres Bilgilerim" bölümününden yeni adres bilgilerinizi girebilir, daha önceden girmiş olduğunuz adres bilgilerinizi değiştirebilirsiniz.`
    // },
    {
      title: "Şifremi nasıl değiştirebilirim?",
      content:
        'Benim Bayim ana sayfasının sağ üst kısmındaki, ad soyadınızın olduğu bölüme tıklayarak "Hesap Bilgilerim" içindeki "Şifre Değiştirme" bölümünden yeni şifrenizi oluşturabilirsiniz. Yeni şifrenizi oluşturduğunuzda ERC Kodunuz ve şifreniz cep telefon numaranıza SMS ile iletilecektir.'
    },

    {
      title: `ERC Kodumu unuttum, ne yapmalıyım?`,
      content: `Benim Bayim’e giriş yapacağınız ERC kodunuzu unuttuysanız, kodunuzu faturanızın üstünde bulabilirsiniz. 0850 532 5 584 (JTI) numaralı Benim Bayim Çağrı Merkezini arayarak da ERC kodunuzu öğrenebilirsiniz.`
    },

    {
      title: `Şifremi unuttum, ne yapmalıyım?`,
      content: `Benim Bayim’e giriş yapacağınız şifrenizi unuttuysanız, giriş ekranında önce “Şifremi Unuttum” butonuna tıklayın. Açılan kutucukta ‘ERC Kodu’ bilginizi ve ekranda görünen doğrulama kodunu ilgili alana girerek “Şifre Gönder” butonuna tıklayın. Yeni şifreniz, sistemde kayıtlı olan cep telefonu numaranıza gönderilir.`
    },

    {
      title: `Bilgilerim güvende mi?`,
      content: `JTI Türkiye olarak kişisel bilgilerinizin güvenliği bizim için de son derece önemli. Kişisel bilgilerinizin güvence altına alınmasına dair detaylı bilgiye <b>JTI Benim Bayim Sitesine İlişkin Şart ve Koşullar</b> bölümünden erişebilirsiniz.`
    },

    {
      title: `Bayimi kapattım veya devrettim, hesabım ne olacak?`,
      content: `Bayinizi kapattığınız veya devrettiğiniz tarihten itibaren hesabınız kapatılacak ve toplamış olduğunuz puanları kullanamayacaksınız.`
    }
  ];

  const accordionItemsContact = [
    {
      title: `Benim Bayim’den gelecek bilgilendirme mesajları tarafıma nasıl ulaşacak?`,
      content: `Benim Bayim ile ilgili gönderilecek tüm bilgilendirme mesajları, sisteme kayıtlı olan cep telefonu numaranıza gönderilecek SMS'ler, mobil uygulamamızı kullanıyorsanız anlık bildirimler ve Benim Bayim’in içinde bulunan Mesajlarım bölümü aracılığı ile size ulaştırılır. Cep telefonu bilginizin güncel olması Benim Bayim’deki yenilik, fırsat ve değişikliklerden anında haberdar olabilmeniz için önemlidir.`
    }
  ];

  {
    /* Yeni Sezon'da Açılacak */
  }
  // const accordionItemsPoints = [
  //   {
  //     title: `Nasıl puan kazanabilirim?`,
  //     content: `Ana sayfada bulunan Nasıl Puan Kazanırım bölümüne tıklayarak, puan kazanımı hakkında detaylı bilgilere ulaşabilirsiniz.`
  //   },

  //   {
  //     title: `Kaç puanım olduğunu nasıl öğrenebilirim?`,
  //     content: `Benim Bayim ana sayfasının sağ üst kısmındaki, ad -
  //           soyadınızın olduğu bölümdeki menüde bulunan "Puan
  //           Geçmişim" bölümünden hangi tarihlerde kaç puan
  //           kazandığınızı görebilir, ayrıca Benim Bayim ana sayfanın
  //           sağ üst köşesinde Ad Soyadınızın altında toplam kaç
  //           puanınız olduğunu takip edebilirsiniz.`
  //   },

  //   {
  //     title: `Puanlarımın süresi ne kadardır?`,
  //     content: `Benim Bayim’in 2023-2024 dönemi 10 Temmuz 2023 ila 09 Haziran 2024 tarihleri arasındadır. Bu dönemde kazandığınız puanlarınızı 09 Haziran 2024
  //     günü saat 23:59’a kadar harcayabilirsiniz. Harcadıktan sonra hesabınızda kalan ya da çeşitli nedenlerle kullanılmamış tüm puanlar silinir.
  //     Silinen puanlara ilişkin JTI’dan hiçbir talepte bulunulamaz.`
  //   },

  //   {
  //     title: `Puanlarımı nasıl harcayacağım?`,
  //     content: `Benim Bayim’de kazandığınız puanları Benim Bayim Dükkanı’nda hediye olarak harcayabilirsiniz. Benim Bayim Dükkanı'nda ürün siparişi verecekseniz,
  //      vermeden önce hediyenin Ürün Özellikleri ve Genel Şartlar bölümlerini okumanızı öneririz. Benim Bayim Dükkanı'nda sipariş ettiğiniz hediyeler
  //      size 25 işgünü içinde kargo aracılığıyla ulaştırılır.`
  //   }
  // ];

  {
    /* Yeni Sezon'da Açılacak */
  }
  // const accordionItemsGifts = [
  //   {
  //     title: `Kazandığım puanlarla hangi hediyelere sahip olabilirim?`,
  //     content: `Benim Bayim Dükkanı'nda puanınıza karşılık gelen hediyeyi alabilirsiniz.`
  //   },

  //   {
  //     title: `Bir dönem içerisinde sipariş edebileceğim hediyelerin sınırı var mı?`,
  //     content: `10 Temmuz 2023 - 09 Haziran 2024 tarihleri arasında sürecek olan Benim Bayim’in 2023-2024 dönemi boyunca Benim Bayim Dükkanı’ndaki
  //     hediyelerden en fazla 3 adet alım yapabilirsiniz.</b>`
  //   },

  //   {
  //     title: `Hediyemi sipariş ederken ad, soyad, adres ve cep telefonu bilgilerimi neden kontrol etmem gerekiyor?`,
  //     content: `Adres alanına girdiğiniz bilgiler, hediye
  //           siparişlerinizin gönderimi için kullanılacağından
  //           bilgilerinizin doğruluğundan lütfen emin olunuz.
  //           Sistemde kayıtlı birden fazla adresiniz varsa
  //           siparişinizin gönderilmesini istediğiniz adresi "Sipariş
  //           Adresi" yaparak onaylayabilirsiniz. Eğer sistemde
  //           kayıtlı adresiniz ya da ad, soyad bilgileriniz yanlışsa,
  //           siparişiniz size ulaşmayacaktır. Bu nedenle bu
  //           bilgilerinizi hediye siparişini vermeden önce kontrol
  //           etmeniz, bir değişiklik varsa "Hesabım" içindeki "Adres
  //           Bilgilerim" bölümününden ya da 0850 532 5 584 (JTI)
  //           numaralı Benim Bayim Çağrı Merkezini arayarak gerekli
  //           güncellemeyi yapmanız çok önemlidir.
  //           <br />
  //           Dikkat: Kargo firmaları Benim Bayim’de kayıtlı ad,
  //           soyadı olmayan birine hediye teslimatını yapamıyorlar.
  //           Kayıtlı ismin bayinizin vergi levhasındaki isim olmasına
  //           gerek yok. Benim Bayim’i kullanan kişinin ismi olması gerekiyor.`
  //   },

  //   {
  //     title: `Hediyemi sipariş ederken internet bağlantım kesilirse ne
  //           yapacağım?`,
  //     content: `Eğer ‘’Siparişi Onayla’’ butonuna tıkladıktan sonra
  //           internet bağlantınız kesildiyse, hediye alım onay mesajı
  //           yerine hata mesajını göreceksiniz. Ancak sistem
  //           üzerinden, satın alma talebiniz bize iletilmiş
  //           olacağından hediye alımınız gerçekleşecektir. Eğer
  //           ‘’Siparişi Onayla’’ butonuna tıklamadan önce internet
  //           bağlantınız kesildiyse hediye satın alma talebiniz bize
  //           ulaşmadığı için hediye alım işleminiz
  //           gerçekleşmeyecektir. Bu gibi durumlarda hediye alım
  //           işleminizin gerçekleşip gerçekleşmediğini “Hesabım”
  //           sayfasında yer alan “Siparişlerim” bölümüne tıklayarak
  //           görebilirsiniz.`
  //   },

  //   {
  //     title: `İstediğim hediyenin ne zamana kadar sitede kalacağını nasıl anlayabilirim?`,
  //     content: `Benim Bayim Dükkanı'nda yer alan ürünler sizler için düzenli olarak güncellenmektedir.`
  //   },

  //   {
  //     title: `Siparişimi nasıl iptal edebilir veya değiştirebilirim?`,
  //     content: `Onaylamış olduğunuz siparişler değiştirilemez ve iptal edilemez. Bu nedenle "Siparişi Onayla" butonuna tıklamadan önce tüm detayları kontrol etmenizi tavsiye ederiz.`
  //   }
  // ];

  {
    /* Yeni Sezon'da Açılacak */
  }
  // const accordionItemsCargo = [
  //   {
  //     title: `Benim Bayim’den almış olduğum hediyeler için kargo ücreti ödüyor muyum?`,
  //     content: `Aldığınız hediyelerin gönderim ücreti Benim Bayim tarafından karşılanır. Bu nedenle herhangi bir kargo ücreti ödemezsiniz.`
  //   },

  //   {
  //     title: `Benim Bayim’den almış olduğum hediyeler nereye ve kime teslim edilecek?`,
  //     content: `Benim Bayim’den aldığınız hediyeler sistemde kayıtlı
  //           bulunan ve sipariş adresi olarak seçtiğiniz adresinize
  //           ve kayıtlı isme teslim edilecek. Bu nedenle adresinizin
  //           doğru olması ve ad - soyad bilgilerinin Benim Bayim
  //           kullanıcısına ve aynı zamanda hediyeyi teslim almak için
  //           dükkanda bulunacak kişiye ait olması hediyenizin en kısa
  //           sürede size ulaşması açısından önemlidir.`
  //   },

  //   {
  //     title: `Benim Bayim’den almış olduğum hediyeler ne zaman ve kim tarafından teslim edilecek?`,
  //     content: `Benim Bayim’den aldığınız hediyeler satın alındıktan sonra yirmi (25) iş günü (resmi tatiller, bayram tatilleri ve haftasonu günleri iş
  //       günlerine dahil değildir) içinde adresinize teslim edilir. Ürün teslimatları Benim Bayim’in anlaşmalı olduğu kargo firmaları tarafından gerçekleştirilir.
  //       Siparişiniz kargoya teslim edildikten sonra ürünlerinizin teslimat süresi, teslimatın yapılacağı adrese ve kargo firmasının teslimat süresine göre
  //       değişkenlik gösterebilir. Siparişinizi ve siparişinizin durumunu “Hesabım – Siparişlerim” sayfasında "Sipariş Durumu" bölümünden durumunu takip
  //       edebilirsiniz. Aynı zamanda Kargo takip numarası ile de siparişinizi ilgili kargo firmasından takip edebilirsiniz.`
  //   },

  //   {
  //     title: `Hediye teslimatı sırasında dükkanda olmazsam ne olacak?`,
  //     content: `Hediyenizin teslimatı için size ilk seferde
  //           ulaşılamazsa, kargo görevlisi not bırakacak ve teslimat
  //           için size bir kez daha uğrayacaktır. 2.seferde yine
  //           ulaşılamazsa, tekrar not bırakılır ve kargo paketi 3 gün
  //           şubede bekletilir, bu süre içinde teslim alınmazsa ürün
  //           geri gönderilir. Geri gönderilen siparişi yeniden almak
  //           için 0850 532 5 584 (JTI) numaralı Çağrı Merkezi’miz ile
  //           iletişime geçerek ürünün yeniden gönderimini talep
  //           edebilirsiniz.`
  //   },

  //   {
  //     title: `Satın aldığım ürün hasarlı olarak elime ulaştı, ne yapmam gerekiyor?`,
  //     content: `Teslimat sırasında siparişinizi açarak sağlamlığını
  //           kontrol ediniz. Herhangi bir hasar tespit edildiğinde,
  //           siparişinizi teslim eden kargo firması ile birlikte
  //           tutanak tutmanız gerekmektedir. Tutanak tutulduktan
  //           sonra 0850 532 5 584 (JTI) numaralı Çağrı Merkezi’mizi
  //           arayarak iade sürecini başlatabilirsiniz. Tutanak
  //           tutulmaması durumunda tarafımıza yapılacak hasarlı ürün
  //           iadeleri ve itirazlar kabul edilmeyecektir.`
  //   },

  //   {
  //     title: `Ürün kurulumu yaptırmak istiyorum. Ne yapmalıyım?`,
  //     content: `Kurulum işlemleri, ürünün türüne göre farklılık
  //           gösterir. <br />
  //           Televizyon, beyaz eşya gibi kurulum gerektiren
  //           ürünlerde, kutusunun açılmaması ve yetkili servisiyle
  //           iletişime geçilerek kurulum talep edilmesi gerekir. Bu
  //           ürünlerde kutuyu yetkili servis açmalı ve kurulumu yine
  //           yetkili servis gerçekleştirmelidir. Aksi halde
  //           ürününüzün garantisi geçerli olmaz. <br />
  //           Elektronik ve küçük ev aletleri, telefon gibi yetkili
  //           servis tarafından kurulum gerektirmeyen ürünlerde ise
  //           paket içeriğinde yer alan kullanım kılavuzundan
  //           yararlanabilirsiniz.`
  //   }
  // ];

  {
    /* Yeni Sezon'da Açılacak */
  }
  // const accordionItemsBadges = [
  //   {
  //     title: `Marka rozetlerini nasıl kazanabilirim?`,
  //     content: `Rozet Macerası Yarışması'nda istediğiniz markayı seçerek, markanın sorularını cevaplayın, her 6 doğru cevapta bir yeni bir rozet kazanacaksınız.
  //      4 markamız ve 5 farklı rozetimiz mevcut. Her markadan 5, toplamda 20 rozet toplayabilirsiniz. Tüm markalar için ilk sıradaki rozeti tamamladıktan sonra
  //      2. rozet için soruları yanıtlamaya devam edebilirsiniz. Ayrıca güncel rozetlerinizi ana sayfa sağ üstte profil bilgilerinizde görebilecek, göstermek
  //      istediğiniz rozetleri hesabım bölümünden seçebileceksiniz.`
  //   },

  //   {
  //     title: `Rozet kazanımları ile ekstra puan kazanacak mıyım?`,
  //     content: `Hayır, rozetler size ekstra puan kazandırmaz. Verdiğiniz
  //           doğru cevap karşılığında yalnızca yarışmanın standart
  //           puanını kazanacaksınız. Ana sayfada bulunan Nasıl Puan
  //           Kazanırım bölümüne tıklayarak, puan kazanımı hakkında
  //           detaylı bilgilere ulaşabilirsiniz.`
  //   }
  // ];

  const accordionItemsWebMobile = [
    {
      title: `Sayfa hata veriyor. Ne yapmalıyım?`,
      content: `Sayfada hatayla karşılaşıyorsanız ilk olarak
            tarayıcınızın ayarlarından çerezleri ve geçmişi
            temizleyin. Bunun ardından işlemi tekrar deneyin. Eğer
            sorun hala devam ediyorsa farklı bir tarayıcıdan işlemi
            gerçekleştirin. Tarayıcılara göre çerezleri ve geçmişi
            temizlemek için:
            <br />
            <br />
            <h3>Google Chrome (Bilgisayar)</h3>
            (<a href="https://support.google.com/accounts/answer/32050?hl=tr" target="_blank">https://support.google.com/accounts/answer/32050?hl=tr</a>)
            <br />
            1. Chrome’u açın.
            <br />
            2. Tarayıcınızın araç çubuğunda Diğer simgesine dokunun.
            <br />
            3. İmleci Daha fazla araç‘ın üzerine getirin ve Tarama
            verilerini temizle‘yi tıklayın.
            <br />
            4. “Tarama verilerini temizle” kutusunda Çerezler, diğer
            site ve eklenti verileri ile Ön belleğe alınmış
            görüntüler ve dosyalar‘ın onay kutularını tıklayın.
            <br />
            5. Silmek istediğiniz veri miktarını seçmek için en
            üstteki menüyü kullanın. Her şeyi silmek için en
            baştan‘ı seçin.
            <br />
            6. Tarama verilerini temizle‘yi tıklayın. <br />
            <br />
            <h3>Google Chrome (Android)</h3>
            (<a href="https://support.google.com/accounts/answer/32050?hl=tr" target="_blank">https://support.google.com/accounts/answer/32050?hl=tr</a>)
            <br />
            1. Chrome’u açın.
            <br />
            2. Tarayıcınızın araç çubuğunda Diğer simgesine dokunun.
            <br />
            3. Geçmiş‘e ve ardından Tarama verilerini temizle‘ye
            dokunun.
            <br />
            4. “Tarama verilerini temizle” altında Çerezler ve site
            verileri ile Ön belleğe alınmış görüntüler ve dosyalar
            onay kutularını seçin.
            <br />
            5. Silmek istediğiniz veri miktarını seçmek için en
            üstteki menüyü kullanın. Her şeyi silmek için en
            baştan‘ı seçin.
            <br />
            6. Tarama verilerini temizle‘ye dokunun.
            <br />
            <br />
            <h3>Google Chrome (IOS)</h3>
            (<a href="https://support.google.com/accounts/answer/32050?hl=tr" target="_blank">https://support.google.com/accounts/answer/32050?hl=tr</a>)
            <br />
            1. Chrome’u açın.
            <br />
            2. Tarayıcınızın araç çubuğunda Diğer simgesine dokunun.
            <br />
            3. Geçmiş‘e ve ardından Tarama verilerini temizle‘ye
            dokunun.
            <br />
            4. “Tarama verilerini temizle” altında Çerezler ve site
            verileri ile Ön belleğe alınmış görüntüler ve dosyalar
            onay kutularını seçin.
            <br />
            5. Silmek istediğiniz veri miktarını seçmek için en
            üstteki menüyü kullanın. Her şeyi silmek için en
            baştan‘ı seçin.
            <br />
            6. Tarama verilerini temizle‘ye dokunun.
            <br />
            <br />
            <h3>Internet Explorer</h3>
            (<a href="https://support.microsoft.com/en-us/help/17438/windows-internet-explorer-view-delete-browsing-history" target="_blank">https://support.microsoft.com/en-us/help/17438/windows-internet-explorer-view-delete-browsing-history</a>)
            <br />
            1. Internet Explorer’da Sık Kullanılanlar düğmesini
            seçin.
            <br />
            2. Geçmiş sekmesini seçip menüden bir filtre seçerek
            geçmişinizi nasıl görüntülemek istediğinizi belirleyin.
            Belirli siteleri silmek için bu listelerin herhangi
            birindeki bir siteye sağ tıklayıp Sil‘i seçin. Ayrıca,
            listedeki herhangi bir siteyi seçerek o siteye
            dönebilirsiniz.
            <br />
            <br />
            <h3>Firefox</h3>
            (<a href="https://support.mozilla.org/tr/kb/firefox-onbellegi-nasil-temizlenir" target="_blank">https://support.mozilla.org/tr/kb/firefox-onbellegi-nasil-temizlenir</a>)
            <br />
            1. Menü düğmesine tıklayın ve Seçenekler‘i seçin.
            <br />
            2. Gelişmiş panelini tıklayarak seçin.
            <br />
            3. Ağ sekmesinin üzerine tıklayın.
            <br />
            4. Çevrim Dışı Depolama kısmı içerisinde, Şimdi temizle
            seçeneğine tıklayın.
            <br />
            5. Seçenekler penceresini kapatmak için Tamam’a
            tıklayın. about:preferences sayfasını kapatın.
            Yaptığınız değişiklikler kendiliğinden kaydedilecek.
            <br />
            <br />
            <h3>Safari</h3>
            1. Önbellek ve çerezleri temizlemek için, Safari > Preferences > Privacy seçin. Remove All Website Data
            butonuna basın.
            <br />
            2. Geçmişi temizlemek için History > Clear History
         hangi tarihten
            itibaren temizlemek <br />
            istediğinizi seçin.
            <br />`
    },
    {
      title: `Benim Bayim mobil uygulamasında sorun var, ne yapmalıyım?`,
      content: `İnternet bağlantılarının çeşitli nedenlerle zayıf olması
            veya zaman zaman kopması nedeniyle mobil uygulamamıza
            erişmekte sorun yaşıyorsanız ilk olarak uygulamayı
            kapatıp tekrar açmanızı tavsiye ederiz. Eğer sorun devam
            ediyorsa güncelleme varsa yapmanızı, yoksa uygulamayı
            silip tekrar yüklemenizi öneririz. Dilerseniz
            telefonunuzun tarayıcısından veya bilgisayardan
            www.jtibenimbayim.com ’u ziyaret ederek alışverişinize
            devam edebilirsiniz.`
    },
    {
      // title: `Sitede görüntüleme/işlem/sipariş esnasında sorun yaşıyorum`,
      title: `Sitede görüntüleme esnasında sorun yaşıyorum`,
      content: `İnternet bağlantılarının çeşitli nedenlerle zayıf olması
            veya anlık sorunlar nedeniyle web sitemizle ilgili
            problem yaşıyorsanız ilk olarak yeni bir sayfa açarak
            www.jtibenimbayim.com ‘a tekrar girmeyi denemenizi
            tavsiye ederiz. Sorun devam ediyorsa modeminizi ya da
            kablosuz ağ bağlantınızı kapatıp tekrar açmanızı
            öneririz. İnternet tarayıcınızın güncelliği,
            jtibenimbayim.com açısından son derece önemlidir.
            <br />
            İnternet tarayıcınızın sürümünü, tarayıcınızda
            “Yardım/Araçlar” menüsündeki “Hakkında” seçeneğini
            kullanarak şimdi kontrol edebilirsiniz.
            <br />
            Eğer internet tarayıcınız:
            <br />
            • Google Chrome ise versiyonu 58 ve üzeri olmalıdır.
            Güncel değilse www.google.com/intl/tr/chrome/ adresinden
            güncelleyebilirsiniz.
            <br />• İnternet Explorer ise versiyonu 11 ve üzeri
            olmalıdır. Güncel değilse
            http://windows.microsoft.com/tr-tr/internet-explorer/download-ie
            adresinden güncelleyebilirsiniz. Tarayıcınızın versiyonu
            11 ve üzeri olduğu halde sorun yaşıyorsanız, Ayarlar
            > Uyumluluk Görünümü Ayarları menüsünde “Intranet
            sitelerini Uyumluluk Görünümü’nde göster.” seçeneğinin
            seçili olmadığını kontrol ediniz.
            <br />
            • Mozilla Firefox ise versiyonu 45 ve üzeri olmalıdır.
            Güncel değilse www.mozilla.org/tr/ adresinden
            güncelleyebilirsiniz.
            <br />
            • Safari internet tarayıcısını www.apple.com/tr/
            sayfasından yükleyebilirsiniz.
            <br />
            • Microsoft Edge ise 81.0.416.64 (Resmi derleme) (64
            bit) güncel versiyon yüklü olmalıdır. Güncel değilse
            https://microsoftedgewelcome.microsoft.com/tr-tr/
            adresinden güncelleyebilirsiniz.
            <br />
            Güvenliğiniz için internet tarayıcınızın güncelliğini
            düzenli aralıklarla kontrol edip güncellemeleri takip
            etmenizi öneririz.`
    }
  ];

  return (
    <div className="pt-12">
      {activeTab === 1 && (
        <Accordion accordionItems={accordionItemsMyAccount} />
      )}

      {activeTab === 2 && <Accordion accordionItems={accordionItemsContact} />}

      {/* {activeTab === 3 && <Accordion accordionItems={accordionItemsPoints} />} */}

      {/* {activeTab === 4 && <Accordion accordionItems={accordionItemsGifts} />} */}

      {/* {activeTab === 5 && <Accordion accordionItems={accordionItemsCargo} />} */}

      {/* {activeTab === 6 && <Accordion accordionItems={accordionItemsBadges} />} */}

      {activeTab === 7 && (
        <Accordion accordionItems={accordionItemsWebMobile} />
      )}
    </div>
  );
};

export default TabContent;
