import "core-js/stable";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as RouterProvider, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import dayjs from "dayjs";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./redux/store";
import { appContext } from "./config/app.config";

import "dayjs/locale/tr";
import "./index.scss";

import CookieConsent from "components/CookieConsent";

dayjs.locale("tr");
const { store, persistor } = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider basename={appContext}>
        <ScrollToTop />
        <App />
        {/* <CookieConsent /> */}
      </RouterProvider>
    </PersistGate>
  </ReduxProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
