import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disclaimerAction, authAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import showMessage from "hooks/showMessage";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const Disclaimer = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const disclaimerList = useSelector(store => store.disclaimer.disclaimerList);

  var disclaimers = null;
  const isLoading = useSelector(store => store.disclaimer.isLoading);

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(!open);

  const [redirect, setRedirect] = useState(false);

  const bg = {
    overlay: {
      background: "#424242"
    }
  };

  useEffect(() => {
    dispatch(
      disclaimerAction.getDisclaimerByFirmId(
        employeeFirm.firm.id,
        employeeFirm.id
      )
    );
    return () => {};
  }, [dispatch, employeeFirm.firm.id, employeeFirm.id]);

  useEffect(() => {
    return () => {};
  }, [disclaimerList]);

  function saveDisclaimer(disclaimer, employeeFirmId, firmId, e) {
    localStorage.setItem("saveDisclaimer", true);
    dispatch(
      disclaimerAction.saveDisclaimer(disclaimer, employeeFirmId, firmId)
    ).then(data => {
      if (data.scoreType) {
        if (data.scoreType === "AYLIK") {
          showMessage(
            "Tebrikler! Bu ay giriş yaptığınız için " +
              data.diffScore +
              " puan kazandınız."
          );
          showMessage(
            "Tebrikler! Bugün giriş yaptığınız için " +
              data.diffDayScore +
              " puan kazandınız."
          );
        } else
          showMessage(
            "Tebrikler! Bugün giriş yaptığınız için " +
              data.diffScore +
              " puan kazandınız."
          );
        dispatch(
          authAction.returnNewScoreOfEmployee(
            employeeFirm.firm.id,
            employeeFirm.id
          )
        );
      }
      redirectToHome();
    });
  }

  function redirectToHome() {
    setTimeout(() => {
      setRedirect(true);
    }, 200);
  }

  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  if (disclaimerList) {
    disclaimers = disclaimerList.map(item => {
      return (
        <div key={item.id}>
          {item.checkedDisclaimerInTheWeek ? (
            redirectToHome()
          ) : (
            <Modal
              open={!open}
              onClose={closeModal}
              role="dialog"
              closeOnEsc={false}
              showCloseIcon={false}
              closeOnOverlayClick={false}
              styles={bg}
              modalId="disclaimer-modal-area"
            >
              <div
                style={{
                  maxHeight: "650px",
                  overflowY: "scroll",
                  marginBottom: "25px"
                }}
              >
                {/* <p>{item.description}</p> */}
                <div
                  style={{ padding: "20px" }}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
              <button
                id="disclaimer-modal-submit"
                onClick={e => {
                  saveDisclaimer(
                    item.id,
                    employeeFirm.id,
                    employeeFirm.firm.id,
                    e
                  );
                  setOpen(!open);
                }}
                style={{
                  borderRadius: "10px",
                  marginBottom: "20px",
                  marginRight: "20px"
                }}
                className="float-right btn btn-success"
              >
                Şart ve Koşulları Okudum, Anladım ve Kabul Ediyorum
              </button>
            </Modal>
          )}
        </div>
      );
    });
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      <div>{disclaimers}</div>
    </div>
  );
};
export default Disclaimer;
