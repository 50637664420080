import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { usePrevious } from "./index";

export default function useHandleRouteWhenAuth() {
  const history = useHistory();
  const location = useLocation();

  const isAuthenticated = useSelector(store => store.auth.isAuthenticated);
  const isPrevAuthenticated = usePrevious(isAuthenticated);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  const disclaimerList = useSelector(store => store.disclaimer.disclaimerList);
  const [disclaimerShowed, setDisclaimerShowed] = useState(false);

  useEffect(() => {
    if (
      disclaimerList &&
      disclaimerList.filter(item => item.checkedDisclaimerInTheWeek !== true)
    ) {
      localStorage.removeItem("saveDisclaimer");
      localStorage.setItem("disclamerForce", true);
      setDisclaimerShowed(true);
      history.push("/disclaimer");
    }
    return () => {};
  }, [disclaimerList, history]);

  useEffect(() => {
    if (
      !isPrevAuthenticated &&
      isAuthenticated &&
      !disclaimerShowed &&
      location.pathname !== "/login" &&
      localStorage.getItem("saveDisclaimer") !== null
    ) {
      const nextRoute = location.pathname || "/";
      history.push(nextRoute);
    }
    return () => {};
  }, [
    isAuthenticated,
    isPrevAuthenticated,
    location,
    history,
    disclaimerShowed
  ]);

  useEffect(() => {
    if (
      !disclaimerShowed &&
      location.pathname !== "/disclaimer" &&
      localStorage.getItem("saveDisclaimer") === null &&
      location.pathname !== "/passwordReset" &&
      location.pathname !== "/login" &&
      employeeFirm &&
      employeeFirm.disclaimerChecked === false
    ) {
      localStorage.removeItem("saveDisclaimer");
      localStorage.setItem("disclamerForce", true);
      setDisclaimerShowed(true);
      history.push("/disclaimer");
    } else if (
      !disclaimerShowed &&
      location.pathname !== "/disclaimer" &&
      location.pathname === "/passwordReset" &&
      isAuthenticated &&
      location.pathname !== "/login"
    ) {
      history.push("/");
    }
  }, [employeeFirm, history, isAuthenticated, location]);
}
