import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { orderAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";
import IllumintionText from "../../../components/IlluminationText";
import PdpPolicy from "../../../components/PdpPolicy";

const ProfileMyDocuments = () => {
  const dispatch = useDispatch();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const orderList = useSelector(store => store.order.orderList);

  useEffect(() => {
    dispatch(orderAction.getOrdersByEmployeeFirmId(employeeFirm.id));
    return () => {};
  }, []);

  return (
    <div
      className="tab-pane fade show active"
      id="orders"
      role="tabpanel"
      aria-labelledby="orders-tab"
    >
      <div className="user-content-item orders">
        <ul className="nav" role="tablist">
          <li>
            <a
              className="active"
              id="illumination-text-tab"
              data-toggle="tab"
              href="#illumination-text"
              role="tab"
              aria-controls="illumination-text"
              aria-selected="true"
            >
              Aydınlatma Metni
            </a>
          </li>
          <li className="nav-item">
            <a
              id="pdp-tab"
              data-toggle="tab"
              href="#pdp"
              role="tab"
              aria-controls="pdp"
              aria-selected="true"
            >
              Kişisel Verilerin Korunması ve İşlenmesi Politikası
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="illumination-text"
            role="tabpanel"
            aria-labelledby="illumination-text-tab"
          >
            <IllumintionText />
          </div>

          <div
            className="tab-pane fade"
            id="pdp"
            role="tabpanel"
            aria-labelledby="pdp-tab"
          >
            <PdpPolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileMyDocuments;
