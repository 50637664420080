import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Accordion from "../../../components/Accordion";

import "./Announcement.scss";

const Announcement = () => {
  function setClicked(id) {
    let collapseFlag = document.querySelectorAll(
      "a[data-target=" + "'#" + id + "'" + "]"
    )[0].ariaExpanded;
    if (collapseFlag === "false") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  const accordionItemsUserGuide = [
    {
      title: `Duyurular`,
      content: `<div>
      Değerli Bayimiz,
      <br />
      <br />
      Benim Bayim yepyeni içerikler, oyunlar, yarışmalar ve sürprizlerle çok yakında tekrar sizlerle olacak. 
      <br />
      <br />
      Bizi takip etmeye devam edin.
      <br />
      <br />
      <h3>Benim Bayim</h3>
    </div>`
    }
  ];

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Duyurular</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li>
                  <Link to={`/userportal/faq`} className="font-medium">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/tac`} className="font-medium">
                    Şartlar Koşullar
                  </Link>
                </li>

                {/* Yeni Sezon'da Açılacak */}
                {/* <li>
                  <Link to={`/userportal/score`} className="font-medium">
                    Nasıl Puan Kazanırım
                  </Link>
                </li> */}

                <li>
                  <Link to={`/userportal/userGuide`} className="font-medium">
                    Kullanım Kılavuzu
                  </Link>
                </li>

                <li className="active">
                  <Link to={`/userportal/announcement`} className="font-medium">
                    Duyurular
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            <Accordion accordionItems={accordionItemsUserGuide} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Announcement;
