import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, profileAction } from "redux/modules";
import { Redirect } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import "./Iys.scss";

const Iys = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.disclaimer.isLoading);
  const iysPopup = useSelector(store => store.home.iysPopup);
  const actives = useSelector(store => store.profile.actives);

  const [popup, setPopup] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (employeeFirm && employeeFirm.id) {
      dispatch(homeAction.getIysPopup(employeeFirm.id));
    }

    return () => {};
  }, [employeeFirm]);

  function redirectToProfile() {
    setTimeout(() => {
      setRedirect(true);
      goToAdress("accountinfo", 0);
    }, 200);
  }

  if (redirect) {
    return <Redirect to={{ pathname: "/userportal/profile" }} />;
  }

  const handleSaveIys = e => {
    e.preventDefault();
    dispatch(homeAction.saveIysPopup(employeeFirm.id)).then(res => {
      if (employeeFirm && employeeFirm.id) {
        dispatch(homeAction.getIysPopup(employeeFirm.id));
      }
    });
  };

  const handleSaveIysRedirect = e => {
    e.preventDefault();

    dispatch(homeAction.saveIysPopup(employeeFirm.id)).then(res => {
      if (employeeFirm && employeeFirm.id) {
        dispatch(homeAction.getIysPopup(employeeFirm.id)).then(resIys => {
          redirectToProfile();
        });
      }
    });
  };

  // Active Tab Codes ...
  function goToAdress(key, index2) {
    [0, 1, 2, 3, 4, 5, 6, 7, 8].map(() => {
      actives.push(false);
    });

    dispatch(
      profileAction.setActives([
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ])
    );

    actives.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });

    dispatch(profileAction.setActives(actives));
    dispatch(profileAction.setProfileContent(key));
  }

  return (
    <div
      className={
        iysPopup && iysPopup.checkedSeenPopUpInTheMonth
          ? "iys-popup d-none"
          : "iys-popup"
      }
    >
      <GlobalLoader isLoading={isLoading} />
      {iysPopup && (
        <div className="iys">
          {iysPopup && iysPopup.popupDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: iysPopup.popupDescription
              }}
            />
          )}

          {/* <p>
            Benim Bayim’den gelen iletileri Hesap Bilgilerim bölümünden, iletişim
            tercihlerinizi tıklayarak ileti alma tercihinizi Onay veya Ret olarak
            değiştirebilirsiniz.
          </p>

          <div>Ret seçimi yaparsanız, Benim Bayim’in;</div>
          <ul className="iys-list">
            <li>Şifre hatırlatma</li>
            <li>Kampanya duyurusu</li>
            <li>Fiyat değişikliği bilgilendirmesi</li>
          </ul>

          <p>SMS’lerini alamayacağınızı hatırlatmak isteriz.</p>

          <p>
            Ayrıca konuyla ilgili 0850 532 5 584(JTI) numaralı Çağrı Merkezimizi
            de arayabilirsiniz.
          </p> */}

          <a
            href="#"
            onClick={e => handleSaveIys(e)}
            className="iys-button btn btn-success mr-3"
          >
            Okudum, anladım.
          </a>
          <a
            href="#"
            onClick={e => handleSaveIysRedirect(e)}
            className="iys-button btn btn-success"
          >
            Okudum, anladım İletişim tercihlerimi düzenlemek istiyorum.
          </a>
        </div>
      )}
    </div>
  );
};
export default Iys;
