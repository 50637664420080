import React, { useState } from "react";
import { Link } from "react-router-dom";

import TabMenu from "../../../components/TabMenu";
import TabContent from "../../../components/TabContent";

const Faq = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };

  return (
    <section>
      <div className="container-fluid mx-auto text-center page-head">
        <h3>Sıkça Sorulanlar</h3>
      </div>

      <div className="container mx-auto my-20">
        <div className="flex flex-col md:flex-row w-100">
          <div className="basis-full md:basis-3/12">
            <div className="detail-page-left-menu">
              <ul>
                <li className="active">
                  <Link to={`/userportal/faq`} className="font-medium">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/tac`} className="font-medium">
                    Şartlar Koşullar
                  </Link>
                </li>

                {/* Yeni Sezon'da Açılacak */}
                {/* <li>
                  <Link to={`/userportal/score`} className="font-medium">
                    Nasıl Puan Kazanırım
                  </Link>
                </li> */}

                <li>
                  <Link to={`/userportal/userGuide`} className="font-medium">
                    Kullanım Kılavuzu
                  </Link>
                </li>

                <li>
                  <Link to={`/userportal/announcement`} className="font-medium">
                    Duyurular
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="basis-full md:basis-9/12 pl-10">
            <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} />
            <TabContent activeTab={activeTab} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
